<template>
  <div class="auth-side tw-relative" />
</template>

<script>
  /**
   * @module component - AuthSide
   */
  export default {
    name: 'AuthSide'
  }
</script>

<style lang="scss" scoped>

  .auth-side {
    background-image: url('~@/assets/img/backgrounds/ceva_login.jpg');
    background-size: cover;
    background-position: center;
  }

</style>
