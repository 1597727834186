<template>
  <Transition name="fade">
    <div
      class="ctk-loading-layer tw-absolute tw-left-0 tw-top-0 tw-bottom-0 tw-right-0 tw-z-50 tw-flex tw-flex-col tw-items-center tw-justify-center"
      :class="{
        'has-background': background
      }"
    >
      <UiLoader
        v-bind="$attrs"
        :size="$attrs.size || 50"
        :background-back="$attrs.backgroundBack || '#000'"
        class="mb-4"
        type="pallet"
      />
      <div
        class="tw-text-base tw-text-gray-700 tw-bg-white tw-px-2 tw-py-1 tw-bg-opacity-75"
        data-test="loading-content"
      >
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - ctkLoadingLayer
   * @param {boolean} [background=true] - Specify if the loader should have the
   * opaque background color or not.
   */
  export default defineComponent({
    name: 'CtkLoadingLayer',
    inheritAttrs: false,
    props: {
      background: {
        type: Boolean,
        default: true
      }
    }
  })
</script>

<style lang="scss" scoped>

  .ctk-loading-layer {
    &.has-background {
      background: rgba(255, 255, 255, 0.4);
    }
  }

</style>
