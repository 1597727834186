<template>
  <div class="password-forgot">
    <div
      class="password-forgot__content"
      data-test="content"
    >
      <h1
        data-test="title"
        class="password-forgot__content__title tw-text-3xl tw-font-light tw-text-center tw-mb-4"
        v-text="$t('auth.titles.password_forgot')"
      />
      <div
        class="password-forgot__content__explanation"
        data-test="explanation"
      >
        <p
          class="tw-text-center"
          v-text="$t('auth.paragraphs.password_forgot.email_explanation')"
        />
        <p
          class="tw-text-center"
          v-text="$t('auth.paragraphs.password_forgot.email_instructions')"
        />
      </div>
    </div>

    <p
      v-if="requestSent"
      class="tw-bg-gray-200 tw-text-sm tw-px-3 tw-py-2 tw-text-gray-700"
      v-text="$t('auth.paragraphs.password_forgot.email_sent')"
      data-test="request-sent"
    />

    <ValidationObserver
      ref="observer"
    >
      <form
        :disabled="$wait.is('submitting password forgot')"
        data-test="form"
        @submit.prevent="submitted"
      >
        <ValidationProvider
          ref="email-provider"
          :name="$t('app.fields.email')"
          rules="required|email"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              v-model="formData.email"
              id="email"
              name="email"
              type="email"
              :error="invalid && validated"
              :hint="errors[0]"
              :data-vv-as="$t('app.fields.email')"
              :label="$t('app.labels.email') | capitalize"
              class="tw-w-full tw-mb-4"
              autocomplete="email"
              data-test="email"
              required
            />
          </template>
        </ValidationProvider>

        <p
          v-if="$err.has('invalid_login')"
          v-text="$t('auth.paragraphs.error.invalid_email')"
          class="tw-bg-red-500 tw-px-4 tw-py-2 tw-text-white"
          data-test="error"
        />

        <UiButton
          :disabled="$wait.is('submitting password forgot')"
          :loading="$wait.is('submitting password forgot')"
          data-test="submit"
          variant="primary"
          size="md"
          class="tw-w-full"
          type="submit"
        >
          {{ $t('auth.buttons.password_forgot') }}
        </UiButton>

        <UiButton
          :to="{
            name: 'SignIn'
          }"
          variant="link"
          class="tw-mt-4 tw-w-full"
          data-test="sign-in"
        >
          {{ $t('auth.buttons.password_forgot_sign_in') }}
        </UiButton>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import { showToaster } from '@/services/Toaster'
  import CtkInputText from '@/components/CtkInputs/CtkInputText'

  /**
   * @module view - PasswordForgot
   */
  export default {
    name: 'PasswordForgot',
    components: {
      CtkInputText
    },
    metaInfo () {
      return {
        title: this.$t('auth.meta.password_forgot_title')
      }
    },
    mounted () {
      this.$err.hide('invalid_login')
    },
    data () {
      return {
        requestSent: false,
        formData: {
          email: null
        }
      }
    },
    methods: {
      ...mapActions('auth', [
        'passwordForgot'
      ]),
      submitted () {
        this.$err.hide('invalid_login')
        this.$refs.observer.validate()
          .then(valid => {
            if (!valid || this.$wait.is('submitting password forgot')) return false

            const { email } = this.formData
            this.$wait.start('submitting password forgot')
            this.passwordForgot({
              email
            })
              .then(() => {
                this.formData.email = null
                this.$refs.observer.reset()

                this.requestSent = true
              })
              .catch(err => {
                this.requestSent = false
                if (!err.response) return

                if (err.response.status === 401) {
                  this.$err.show('invalid_login')
                } else {
                  let errorMessage = this.$t('an_error_has_occurred')

                  const data = err.response && err.response.data

                  if (data && data.error && data.error.violations) {
                    data.error.violations.forEach(violation => {
                      const provider = this.$refs[`${violation.property_path}-provider`]
                      if (provider) {
                        provider.setErrors([violation.message])
                      }
                    })

                    return false
                  } else if (data && data.error && data.error.title) {
                    errorMessage = data.error.title
                  }

                  showToaster(this, errorMessage, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => {
                this.$wait.end('submitting password forgot')
              })
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
.password-forgot__content {
  margin-bottom: 2.5rem;
}
.password-forgot__content__explanation {
  color: $secondary-text;
}
@media only screen and (max-width: $breakpoint-mobile-m) {
  .password-forgot__content {
    margin-top: 16px;
    margin-bottom: 64px;
  }
}
</style>
