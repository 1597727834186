<template>
  <div
    class="invite-expired tw-flex tw-flex-col tw-items-center tw-w-full tw-py-16"
    data-test="container"
  >
    <div
      class="tw-flex tw-flex-col tw-items-center tw-text-center"
    >
      <picture class="tw-mb-8">
        <img
          src="@/assets/img/icons/rejected.svg"
          width="70"
          height="70"
          alt=""
          data-test="icon"
        >
      </picture>
      <h1
        v-text="title"
        class="tw-text-2xl tw-mb-4"
        data-test="title"
      />
      <p
        class="tw-text-center tw-mb-6"
        v-text="subtitle"
        data-test="subtitle"
      />

      <ui-button
        v-if="contactFormLink"
        :href="contactFormLink"
        v-text="contactBtnTitle"
        class="link"
        variant="primary"
        size="sm"
        rel="noopener"
        data-test="link"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import { i18n } from '@/locales'

  import chronoinfos from '@/../config/chronoinfos'

  export default {
    name: 'InviteExpired',
    sync: true,
    data () {
      return {
        contactBtnTitle: null,
        title: null,
        subtitle: null
      }
    },
    props: {
      isCarrier: {
        type: Boolean,
        required: true
      },
      /**
       * As per https://github.com/chronotruck/chronotruck/pull/8900
       * the locale value provided by the API has the following format (when provided):
       * { "region":"US","primary_language":"en","value":"en_US" }
       * Here the "locale" value matches the "primary_language" value coming from the Invite component
       */
      locale: {
        type: String,
        default: null
      }
    },
    computed: {
      userType () {
        return this.isCarrier === true ? 'carrier' : 'shipper'
      },
      formLocale () {
        return this.locale && this.locale === 'fr' ? 'fr' : 'other'
      },
      contactFormLink () {
        const faqContactForm = chronoinfos.forms.contact.faq

        // TODO: add conditions on value existence
        return faqContactForm[this.userType][this.formLocale]
      },
      myLocale () {
        return i18n.locale
      }
    },
    methods: {
      ...mapActions('ui', [
        'setShowAuthCtaBar'
      ])
    },
    beforeMount () {
      this.setShowAuthCtaBar(false)
    },
    created () {
      this.contactBtnTitle = i18n.t('shipments.buttons.driver_pallet_network_question', i18n.locale)
      this.title = this.$i18n.t('account.members.titles.invite_expired', i18n.locale)
      this.subtitle = i18n.t('account.members.paragraphs.invite_expired', i18n.locale)
    },
    beforeUnmount () {
      this.setShowAuthCtaBar(true)
    },
    beforeDestroy () {
      this.setShowAuthCtaBar(true)
    }
  }
</script>

<style lang="scss" scoped>

.link {
  padding: 8px 16px;
}

</style>
