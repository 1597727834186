<template>
  <div
    class="invite"
  >
    <CtkLoadingLayer
      v-if="$wait.is('fetching invite')"
      data-test="loading"
    >
      {{ $t('app.labels.loading') }}
    </CtkLoadingLayer>
    <div
      v-else
      class="invite__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0"
    >
      <div
        class="invite__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"
      >
        <div class="tw-mb-10 tw-flex tw-flex-col">
          <h1
            v-text="$t('auth.titles.invite')"
            class="invite__header-title tw-text-xl tw-font-normal tw-flex-1 tw-pb-2"
            data-test="title"
          />
        </div>

        <ValidationObserver
          ref="observer"
          slim
        >
          <form
            :disabled="$wait.is('creating account')"
            data-test="form"
            @submit.prevent="submitted"
          >
            <ValidationProvider
              ref="first_name-provider"
              :name="$t('app.fields.first_name')"
              rules="required|max:255"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="first_name"
                  v-model="formData.firstName"
                  :label="$t('app.labels.first_name')"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :disabled="$wait.is('creating account')"
                  type="text"
                  name="first_name"
                  autocomplete="given-name"
                  class="tw-mb-4"
                  required
                />
              </template>
            </ValidationProvider>
            <ValidationProvider
              ref="last_name-provider"
              :name="$t('app.fields.last_name')"
              rules="required|max:255"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="last_name"
                  v-model="formData.lastName"
                  :label="$t('app.labels.last_name')"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :disabled="$wait.is('creating account')"
                  :data-vv-as="$t('app.fields.last_name')"
                  type="text"
                  name="last_name"
                  autocomplete="family-name"
                  class="tw-mb-4"
                  required
                />
              </template>
            </ValidationProvider>
            <ValidationProvider
              ref="phone-provider"
              :rules="`telephone:${formData.country}`"
              :name="$t('phone')"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-phone-number
                  id="phone"
                  v-model="formData.phone"
                  :country.sync="formData.country"
                  :hint="errors[0]"
                  :disabled="$wait.is('creating account')"
                  :error="invalid && validated || errors && errors.length > 0"
                  name="phone"
                  autocomplete="tel-national"
                  class="tw-mb-4"
                  @update="phoneUpdated"
                />
              </template>
            </ValidationProvider>
            <ValidationProvider
              ref="password-provider"
              vid="password"
              :name="$t('app.fields.password')"
              rules="required|min:12"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  v-model="formData.password"
                  id="password"
                  name="password"
                  type="password"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :label="$t('app.labels.password_invite') | capitalize"
                  :disabled="$wait.is('creating account')"
                  class="tw-w-full tw-mb-4"
                  autocomplete="new-password"
                  data-test="password"
                  required
                />
              </template>
            </ValidationProvider>

            <ValidationProvider
              ref="password-confirmation-provider"
              :name="$t('app.fields.password')"
              rules="required|min:12|confirmed:password"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  v-model="formData.passwordConfirmation"
                  id="password-confirmation"
                  name="password-confirmation"
                  type="password"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :label="$t('app.labels.password_confirmation') | capitalize"
                  :disabled="$wait.is('creating account')"
                  class="tw-w-full tw-mb-4"
                  autocomplete="new-password"
                  data-test="password-confirmation"
                  required
                />
              </template>
            </ValidationProvider>

            <p
              class="password__form__password-explanation tw-mb-6 tw-italic"
              data-test="password-constraint"
            >
              {{ $t('app.password.paragraphs.password_constraint') }}
            </p>

            <ValidationProvider
              ref="tos-provider"
              name="tos"
              rules="required|length:4"
              data-test="tos-provider"
            >
              <template slot-scope="{ invalid, validated }">
                <b-form-checkbox
                  v-model="formData.tos"
                  :disabled="$wait.is('creating account')"
                  :state="invalid && validated ? false : null"
                  class="tw-text-secondary-text tw-mt-4"
                  data-test="terms"
                  value="true"
                  required
                >
                  <div class="tw-inline tw--mt-1">
                    <i18n
                      path="app.paragraphs.register.tos"
                      tag="span"
                    >
                      <template #tos>
                        <UiLink
                          :href="tos"
                          target="_blank"
                          rel="noopener"
                          v-text="$t('app.paragraphs.register.tos_label')"
                          data-test="tos"
                        />
                      </template>
                    </i18n>
                  </div>
                </b-form-checkbox>
                <div
                  v-if="invalid && validated"
                  class="tw-text-red-500 tw-pl-6 tw-mt-1 tw-text-xs"
                  v-text="$t('validator.required')"
                  data-test="error"
                />
              </template>
            </ValidationProvider>

            <ui-button
              :disabled="$wait.is('creating account')"
              type="submit"
              size="md"
              class="tw-w-full tw-mt-8"
              variant="primary"
              data-test="submit"
            >
              {{ $t('validate') | capitalize }}
            </ui-button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, onMounted, reactive, ref } from '@vue/composition-api'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'

  import useRoute from '@/composables/useRoute'
  import useWait from '@/composables/useWait'
  import useRouter from '@/composables/useRouter'
  import useStore from '@/composables/useStore'

  import { Registration } from '@/resources'
  import handlePropertyPathViolations from '@/resources/handlers/violations'

  import Config from '@/services/Config'
  import Storage from '@/services/Storage'
  import { showToaster } from '@/services/Toaster'

  import { loadLanguageAsync, i18n } from '@/locales'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'
  import CtkPhoneNumber from '@/components/CtkPhoneNumber/index.vue'

  /**
   * @module view - Invite
   */
  export default defineComponent({
    name: 'Invite',
    components: {
      CtkInputText,
      CtkLoadingLayer,
      CtkPhoneNumber
    },
    setup (props, { root, refs }) {
      const wait = useWait()
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      /** @type {any} */
      const observer = ref(null)

      const formData = reactive({
        firstName: null,
        lastName: null,
        country: 'FR',
        phone: null,
        phoneFormatted: null,
        password: null,
        passwordConfirmation: null,
        tos: false
      })

      onMounted(() => {
        wait.start('fetching invite')

        Registration.fetchUserInvite({
          uid: route.value.query.uuid
        })
          .then(async ({ data }) => {
            if (!data.is_enabled) {
              const locale = data.existing_user.locale ? data.existing_user.locale.primary_language : 'en'

              await loadLanguageAsync(locale)
              Storage && Storage.setItem('userLocale', locale)

              router.value.push({
                name: 'InviteExpired',
                query: {
                  locale
                },
                params: {
                  isCarrier: data.is_carrier,
                  locale
                }
              })
                .catch(() => {})
            }

            if (data.existing_user) {
              const parsedPhone = data.existing_user.phone ? parsePhoneNumberFromString(data.existing_user.phone) : null

              Object.assign(formData, {
                firstName: data.existing_user.first_name,
                lastName: data.existing_user.last_name,
                country: parsedPhone && parsedPhone.country ? parsedPhone.country : 'FR',
                phone: parsedPhone ? parsedPhone.formatNational() : data.existing_user.phone,
                phoneFormatted: parsedPhone ? parsedPhone.formatInternational() : null
              })
            }
          })
          .catch(err => {
            console.log('error fetching the invite data', {
              err
            })
          })
          .finally(() => {
            wait.end('fetching invite')
          })
      })

      const phoneUpdated = (v) => {
        formData.country = v.countryCode || 'FR'
        formData.phoneFormatted = v.formattedNumber
      }

      const tos = `${Config.get('tos')}`

      function submitted () {
        if (!observer.value) return

        observer.value.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            if (!(formData.tos)) return

            const { firstName, lastName, phoneFormatted, phone, password } = formData

            wait.start('creating account')
            Registration.userInvite({
              uid: route.value.query.uuid
            }, {
              first_name: firstName,
              last_name: lastName,
              phone: (phoneFormatted || phone),
              password,
              locale: i18n.locale
            })
              .then(({ data }) => {
                store.value.dispatch('auth/register/setAccount', {
                  email: data.email,
                  password
                })

                router.value.push({
                  name: 'InviteConfirmation'
                })
                  .catch(() => {})
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error && data.error.violations) {
                  handlePropertyPathViolations.call(root, data.error.violations, refs)
                } else {
                  showToaster(null, data.error.title, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => {
                wait.end('creating account')
              })
          })
      }

      return {
        observer,
        submitted,
        tos,
        formData,
        phoneUpdated
      }
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      const { uuid } = to.query
      if (!uuid) {
        return next({
          name: 'AuthView'
        })
      }

      next()
    }
  })
</script>

<style lang="scss" scoped>
.invite__header-title {
  position: relative;
}
.invite__header-title::after {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  content: '';
  height: 1px;
  background-color: $divider;
}
</style>
