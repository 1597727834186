<template>
  <div class="sign-in">
    <div
      class="sign-in__content tw-py-4 md:tw-pb-10"
      data-test="content"
    >
      <h1
        data-test="title"
        class="sign-in__content__title tw-text-3xl tw-font-light tw-text-center tw-mb-4"
        v-text="$t('auth.titles.sign_in')"
      />
      <h2
        data-test="subtitle"
        class="sign-in__content__subtitle tw-text-gray-600 tw-text-xl tw-font-light tw-text-center"
        v-text="$t('auth.titles.sign_in_subtitle')"
      />
    </div>
    <ValidationObserver
      ref="observer"
    >
      <form
        :disabled="$wait.is('signing in')"
        data-test="form"
        @submit.prevent="submitted"
      >
        <ValidationProvider
          ref="email-provider"
          :name="$t('app.fields.email')"
          rules="required|email"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              v-model="formData.email"
              id="email"
              name="email"
              type="email"
              :error="invalid && validated"
              :hint="errors[0]"
              :label="$t('app.labels.email') | capitalize"
              :disabled="$wait.is('signing in')"
              class="tw-w-full tw-mb-4"
              autocomplete="email"
              data-test="email"
              required
            />
          </template>
        </ValidationProvider>

        <ValidationProvider
          :name="$t('app.fields.password')"
          rules="required"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              v-model="formData.password"
              id="password"
              name="password"
              type="password"
              :error="invalid && validated"
              :hint="errors[0]"
              :label="$t('app.labels.password') | capitalize"
              :disabled="$wait.is('signing in')"
              class="tw-w-full tw-mb-4"
              autocomplete="current-password"
              data-test="password"
              required
            />
          </template>
        </ValidationProvider>

        <p
          v-if="$err.has('invalid_login')"
          v-text="$t('auth.paragraphs.error.invalid_email_password')"
          class="tw-bg-red-500 tw-px-4 tw-py-2 tw-text-white"
          data-test="error"
        />

        <p
          v-if="$err.has('user_disabled')"
          v-text="$t('auth.paragraphs.error.user_disabled')"
          class="tw-bg-red-500 tw-px-4 tw-py-2 tw-text-white"
          data-test="error"
        />

        <UiButton
          :disabled="$wait.is('signing in')"
          :loading="$wait.is('signing in')"
          variant="primary"
          class="tw-w-full"
          type="submit"
          data-test="submit"
        >
          {{ $t('auth.buttons.sign_in') }}
        </UiButton>

        <UiButton
          :to="{
            name: 'PasswordForgot'
          }"
          variant="link"
          class="tw-mt-4 tw-w-full"
          data-test="password-forgot"
        >
          {{ $t('auth.buttons.password_forgot_link') }}
        </UiButton>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import { showToaster } from '@/services/Toaster'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'

  /**
   * @module view - SignIn
   */
  export default defineComponent({
    name: 'SignIn',
    components: {
      CtkInputText
    },
    data () {
      return {
        formData: {
          email: null,
          password: null
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'isFirstConnection'
      ])
    },
    mounted () {
      this.$err.hide('invalid_login')
    },
    methods: {
      ...mapActions([
        'setAppReady'
      ]),
      ...mapActions('auth', [
        'signIn'
      ]),
      ...mapActions('ui', [
        'setWelcomeVisible'
      ]),
      submitted () {
        this.$err.hide('invalid_login')
        // @ts-ignore
        this.$refs.observer.validate()
          // @ts-ignore
          .then(valid => {
            if (!valid || this.$wait.is('signing in')) return false

            const { email, password } = this.formData
            this.$wait.start('signing in')
            this.signIn({
              email,
              password
            })
              // @ts-ignore
              .then(async () => {
                this.$router.push({
                  name: 'Dashboard'
                })
                  .catch(() => {})
                  .finally(() => {
                    this.setAppReady(true)
                    this.$wait.end('signing in')

                    if (this.isFirstConnection) setTimeout(this.setWelcomeVisible, 2000, true)
                  })
              })
              // @ts-ignore
              .catch(err => {
                if (!err.response) return

                if (err && err.response && err.response.status === 401) {
                  if (err.response.data.error.title === 'user_disabled') {
                    this.$err.show('user_disabled')
                  } else {
                    this.$err.show('invalid_login')
                  }
                } else {
                  showToaster(this, this.$t('an_error_has_occurred'), {
                    type: 'error',
                    position: 'bottom-left'
                  })
                }
              })
              .finally(() => {
                this.$wait.end('signing in')
              })
          })
      }
    }
  })
</script>
