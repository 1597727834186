<template>
  <div class="auth-header tw-flex tw-justify-between tw-items-center tw-mt-3 tw-px-3">
    <router-link
      :to="{ name: 'AuthView' }"
      class="auth-header__logo"
    >
      <picture>
        <source
          srcset="@/assets/img/logo_bg_white.svg"
          media="(max-width: 425px)"
        >
        <img
          src="@/assets/img/ceva-dark.svg"
          alt="Logo CEVA"
          height="38"
        >
      </picture>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'AuthHeader',
    data () {
      return {
        isSignInRoute: false,
        routeName: null
      }
    },
    watch: {
      $route: {
        handler: function (to) {
          this.isSignInRoute = to.name === 'SignIn'
          this.routeName = to.name
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .auth-header {
    flex: 0 0 55px;
  }

</style>
